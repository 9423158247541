import { emailReg } from './regExp';

const fieldValidation = {
  optionalString: (s?: string | null) => !s || !!s,
  requiredString: (s?: string | null) => !!s,
  requiredStringWithMinLength: (s: string | null | undefined, l: number) => !!s && s.length >= l,
  optionalBool: (s?: boolean | null) => !s || !!s,
  optionalNumber: (s?: string | number | null) => s === null || s === undefined || !isNaN(+s),
  requiredNumber: (s?: string | number | null) => s !== null && s !== undefined && !isNaN(+s),
  optionalEmail: (s?: string | null) => !s || emailReg.test(s),
  requiredEmail: (s?: string | null) => emailReg.test(s || ''),
};

export default fieldValidation;
