import { useMemo } from 'react';
import { LoginFormValidation } from './';

const useValidation = (validation: LoginFormValidation) => {
  const valid = useMemo(() => validation.email && validation.password, [
    validation.email,
    validation.password,
  ]);
  return valid;
};

export default useValidation;
