import React, { SyntheticEvent, useCallback, useReducer } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Container,
  Grid,
  Box,
  FormHelperText,
} from '@material-ui/core';

import SEO from '../components/seo';
import DefaultLink from '../components/DefaultLink';
import FormPaper from '../components/account/form/form-paper';
import FormAvatar from '../components/account/form/form-avatar';
import ButtonProgressContainer from '../components/account/form/button-progress-container';
import CircularProgressOverlay from '../components/account/form/circular-progress-overlay';
import useAuth from '../State/Auth/useAuth';
import loginReducer, {
  initialState,
  SET_CHANGE,
  SET_BLUR,
  SET_VALIDATE_EVERYTHING,
  LoginForm,
} from '../reducers/login';
import useValidation from '../reducers/login/useValidation';
import { Alert } from '@material-ui/lab';

const Login = () => {
  const { signIn, requestSignIn } = useAuth();
  const [state, dispatch] = useReducer(loginReducer, initialState());
  const valid = useValidation(state.validation);

  const onChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
      dispatch({
        type: SET_CHANGE,
        name: e.currentTarget.name as keyof LoginForm,
        value: e.currentTarget.value,
      }),
    []
  );

  const onBlur = useCallback(
    (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      dispatch({
        type: SET_BLUR,
        name: e.currentTarget.name as keyof LoginForm,
      }),
    []
  );

  const onSignIn = useCallback(() => {
    if (valid) {
      signIn(state.value.email, state.value.password);
    } else {
      dispatch({
        type: SET_VALIDATE_EVERYTHING,
      });
    }
  }, [state]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        onSignIn();
      }
    },
    [onSignIn]
  );

  return (
    <>
      <SEO title="Login" />
      <Container maxWidth="sm">
        <form>
          <FormPaper>
            <FormAvatar>
              <LockOutlinedIcon />
            </FormAvatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={state.interact.email && !state.validation.email}
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                value={state.value.email}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                autoComplete="email"
              />
              {state.interact.email && !state.validation.email && (
                <FormHelperText>Must provide a valid email.</FormHelperText>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              required
              fullWidth
              error={state.interact.password && !state.validation.password}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                value={state.value.password}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {state.interact.password && !state.validation.password && (
                <FormHelperText>Must provide a password.</FormHelperText>
              )}
            </FormControl>
            <Box mt={2} width="100%">
              <ButtonProgressContainer fullWidth>
                <Button
                  disabled={requestSignIn.loading}
                  type="submit"
                  onClick={onSignIn}
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="button"
                >
                  Sign in
                </Button>
                {requestSignIn.loading && <CircularProgressOverlay size={30} />}
              </ButtonProgressContainer>
            </Box>
            {requestSignIn.errorMessage && (
              <Box mt={2} width="100%">
                <Alert severity="error">{requestSignIn.errorMessage}</Alert>
              </Box>
            )}
            <Box mt={2} width="100%">
              <Grid container>
                <Grid item xs>
                  <DefaultLink href="/forgot-password" variant="body2">
                    Forgot password?
                  </DefaultLink>
                </Grid>
                <Grid item>
                  <DefaultLink href="/sign-up" variant="body2">
                    Don&apos;t have an account? Sign Up
                  </DefaultLink>
                </Grid>
              </Grid>
            </Box>
          </FormPaper>
        </form>
      </Container>
    </>
  );
};

export default Login;
